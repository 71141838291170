import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Navbar from "./navbar";

import "normalize.css";
import "../css/global.scss";

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <div className="container">
        <Header />
        <Navbar />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default TemplateWrapper;
