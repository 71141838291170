import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [navBarActiveClass, setNavBarActiveClass] = useState("");
  const data = useStaticQuery(graphql`
    {
      allSettingsYaml(
        filter: { menuItems: { elemMatch: { label: { ne: null } } } }
      ) {
        nodes {
          menuItems {
            label
            path
          }
        }
      }
    }
  `);

  let links = [];
  const menuItems = data.allSettingsYaml.nodes[0].menuItems;

  for (let i = 0; i < menuItems.length; i++) {
    const path = menuItems[i].path;
    const label = menuItems[i].label;

    links.push(
      <Link activeClassName="active" to={path} key={i}>
        {label}
      </Link>
    );
  }

  useEffect(() => {
    document.addEventListener("click", e => {
      const navbarBurger = document.querySelector(".navbar-burger");
      const nabvarMenu = document.querySelector(".navbar-menu");

      let targetElement = e.target;

      do {
        if (targetElement === navbarBurger || targetElement === nabvarMenu) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      setNavBarActiveClass("");
      setActive(false);
    });
  }, []);

  useEffect(() => {
    if (active) {
      setNavBarActiveClass("is-active");
    } else {
      setNavBarActiveClass("");
    }
  }, [active]);

  return (
    <nav role="navigation" aria-label="main-navigation">
      <div>
        <div className="navbar-brand">
          <div
            className="navbar-burger burger"
            data-target="navMenu"
            onClick={() => {
              setActive(!active);
            }}
          >
            <div className={`navbar-burger__button ${navBarActiveClass}`}>
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="navbar-burger__label">Menu</div>
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          {links}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
