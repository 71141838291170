import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "./siteMetaData";
import favicon16 from "../images/favicon/favicon-16x16.png";
import favicon32 from "../images/favicon/favicon-32x32.png";
import safaripinned from "../images/favicon/safari-pinned-tab.svg";

const Head = ({ title, description }) => {
  return (
    <Helmet>
      <html lang="nl" />
      <title>{title ? title : useSiteMetadata().title}</title>
      <meta
        name="description"
        content={description ? description : useSiteMetadata().description}
      />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="business.business" />
      <meta
        property="og:title"
        content={title ? title : useSiteMetadata().title}
      />
      <meta property="og:url" content="/" />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="mask-icon" href={safaripinned} color="#255510" />
      <meta name="msapplication-TileColor" content="#255510" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  );
};

export default Head;
