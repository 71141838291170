import React from "react";
import logo from "../images/logo.png";
import { Link } from "gatsby";

const Header = () => {
  return (
    <header className="header">
      <div className="logo-wrapper">
        <Link to="/" className="navbar-item" title="Logo">
          <img src={logo} alt="Bureau Romme" />
        </Link>
      </div>
      <div className="tagline">
        met Bezieling <span>Richting geven</span>
      </div>
    </header>
  );
};

export default Header;
