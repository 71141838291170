import React from "react";
import { Link, withPrefix } from "gatsby";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import linkedin from "../images/icon-linkedin.svg";

const Footer = () => {
  const { keurmerkRLP } = useStaticQuery(
    graphql`
      {
        keurmerkRLP: file(relativePath: { eq: "Keurmerk_RLP_RGB_Online.png" }) {
          childImageSharp {
            fluid(sizes: "200px") {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <footer className="footer">
      <div className="footer__top">
        <ul>
          <li>
            <a
              href="https://linkedin.com/in/moniek-romme-2b67628"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="icon-linkedin" alt="Linkedin" src={linkedin} />
            </a>
          </li>
          <li>Bureau Romme</li>
          <li>0612520587</li>
          <li>
            <a href="mailto:info@bureauromme.nl">info@bureauromme.nl</a>
          </li>
          <li>
            <Link to="/">www.bureauromme.nl</Link>
          </li>
          <li>Grote Spie 28 </li>
          <li>4819 cx</li>
          <li>Breda</li>
        </ul>
      </div>
      <div className="footer__middle">
        <ul>
          <li>
            <a
              href={withPrefix("/assets/algemene-voorwaarden.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Algemene voorwaarden
            </a>
          </li>
          <li>
            <a
              href={withPrefix("/assets/privacy-verklaring.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy verklaring
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__bottom">
        <a
          href="https://www.noloc.nl/leden/moniek-romme-rl/?id=52cd49fe7ad16232040009f2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            fluid={keurmerkRLP.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
