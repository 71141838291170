import React from "react";
import { graphql } from "gatsby";
import TemplateWrapper from "../components/templateWrapper";
import Img from "gatsby-image";
import Content, { HTMLContent } from "../components/content";
import Head from "../components/head";

export const Template = ({ html, quote, images, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="content-wrapper">
      <div className="content-inner">
        <div className="content-inner__left">
          <PageContent content={html} />
        </div>
        <div className="content-inner__right">
          <h2 className="content-inner__quote">{quote}</h2>
          {images
            ? images.map(({ image }, i) => {
                return image && image.childImageSharp ? (
                  <Img key={i} fluid={image.childImageSharp.fluid} />
                ) : (
                  <img key={i} alt={quote} src={image} />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

const Page = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <div>
      <Head
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <TemplateWrapper>
        <Template
          html={post.html}
          contentComponent={HTMLContent}
          quote={post.frontmatter.aside.quote}
          images={post.frontmatter.aside.images}
        />
      </TemplateWrapper>
    </div>
  );
};

export default Page;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        aside {
          quote
          images {
            image {
              childImageSharp {
                fluid(sizes: "300px") {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
